import { CircularProgress, Collapse, Drawer } from '@mui/material';
import { t } from 'i18next';
import { useRef, useState } from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import { auth } from '../firebase.config';
import { Account, Admin, Affiliate, ArrowRightSolid, BuildingHouse, Bulb, BurgerMenu, Cart, CheckmarkCircle, CloseX, Dashboard, DotOpen, Graph, Heart, Login, Logout, Mail, PersonOutline, Register, Searches, Settings, Team, Update, Upgrade } from '../helper/icons';
import useOutsideClick from '../hooks/useOutsideClick';
import Footer from './Footer';
import AnimationIcon from '../components/Generics/AnimationIcon';

const Topbar = () => {

  const { handleMenuState, toggleMenu, setToggleMenu, customerChats, setLoginOpen, adminEmail, setLoginHandlerState, screenSize } = useStateContext();

  const handleMenuClick = (newMenuState) => {
    	handleMenuState(newMenuState);
      return;
  };

  const handleLoginClick = (newState) => {
    setLoginHandlerState(newState);
    setLoginOpen(true);
    setToggleMenu(false);
    return;
  };

  const handleLogoutClick = async () => {
    handleMenuState("dashboard");
    try {
      await auth.signOut()
    } catch(error) {
      console.error(error.message);
    };
    return;
  };

  return (
    <>
    <div className='w-full flex flex-col bg-white py-3 h-24 fixed top-0 z-50'>
      <div className={`w-full flex flex-row items-center pl-1 pr-2 sm:pl-3 sm:pr-4 pt-2`}>
          <BurgerMenu onClick={() => setToggleMenu(!toggleMenu)} className='text-slate-800 cursor-pointer hover:text-slate-400 active:text-slate-800' size={28}/>
          <div className='flex flex-col justify-center items-start ml-2 md:ml-3'>
            <div className='flex flex-col justify-center items-center relative'>
              <img className='cursor-pointer' onClick={() => handleMenuState("dashboard")} width={screenSize.width > 380 ? 150 : 120} src={'https://firebasestorage.googleapis.com/v0/b/move-58c8d.appspot.com/o/allgemein%2FAllimmomatch.png?alt=media&token=e6afb35b-6fb7-46dd-831f-4dd1b233b598'} alt='ALLIMMO'/>
              <p className='font-bold text-sm text-orange-400 absolute -bottom-2 right-1.5 sm:-bottom-1.5'>beta</p>
            </div>
          </div>
        {auth.currentUser &&
        <div className={`flex flex-col justify-center items-end grow`}>
          <AccountMenu logout={() => handleLogoutClick()} handleClick={handleMenuClick} />
        </div>}
      </div>
    {/* Menu */}
    <Drawer open={toggleMenu} anchor='left' onClose={() => setToggleMenu(false)}>
      <div className='flex flex-col w-250 h-full pb-2 select-none'>
        <div className='flex flex-col justify-center items-center relative w-full my-5'>
          <img className='cursor-pointer' onClick={() => handleMenuClick("dashboard")} width={150} src={'https://firebasestorage.googleapis.com/v0/b/move-58c8d.appspot.com/o/allgemein%2FAllimmomatch.png?alt=media&token=e6afb35b-6fb7-46dd-831f-4dd1b233b598'} alt='ALLIMMO'/>
          <p className='font-bold text-sm text-orange-400 absolute -bottom-1.5 right-12'>beta</p>
        </div>
        {!auth.currentUser && <MenuElement clickFunction={() => handleLoginClick("login")} icon={<Login size={25}/>} label={t('General.Login')}/>}
        {!auth.currentUser && <MenuElement clickFunction={() => handleLoginClick("register")} icon={<Register size={25}/>} label={t('Menu.Register')}/>}
        {auth.currentUser && <MenuElement clickFunction={() => handleMenuClick("dashboard")} icon={<Dashboard size={25}/>} label={t('Menu.Dashboard')}/>}
        {auth.currentUser && <MenuElement clickFunction={() => handleMenuClick("objects")} icon={<BuildingHouse size={25}/>} label={t('Menu.MeineObjekte')}/>}
        {auth.currentUser && <MenuElement clickFunction={() => handleMenuClick("searches")} icon={<Searches size={25}/>} label={t('Menu.MeineSuchen')}/>}
        {(auth.currentUser && customerChats.length > 0) && <MenuElement showDot={customerChats.some(c => c.newMsg.includes(auth.currentUser.uid))} clickFunction={() => handleMenuClick("chats")} icon={<Mail size={25}/>} label={t('Menu.Nachrichten')}/>}
        {(auth.currentUser?.email === adminEmail || auth.currentUser?.uid === "tKqrVhKq1kObfB19b9DsyX0L7jd2")  && <MenuElement clickFunction={() => handleMenuClick("stats")} icon={<Graph size={25}/>} label={t('Menu.Stats')}/>}
        {auth.currentUser?.email === adminEmail && <MenuElement clickFunction={() => handleMenuClick("admin")} icon={<Admin size={25}/>} label={t('Menu.Admin')}/>}
        {/* {(auth.currentUser && auth.currentUser?.email !== adminEmail) &&  <MenuElement clickFunction={() => handleMenuClick("rating")} icon={<Star size={25}/>} label={t('Menu.Bewertung')}/>} */}
        <MenuElement clickFunction={() => handleMenuClick("team")} icon={<Team size={25}/>} label={t('Menu.Team')}/>
        {/* <MenuElement clickFunction={() => handleMenuClick("tips")} icon={<Tips size={25}/>} label={t('Menu.Tips')}/> */}
        <MenuElement multi menuElements={[
          <MenuSubElement clickFunction={() => handleMenuClick("about")} label={t('Menu.Erklärung')}/>,
          <MenuSubElement clickFunction={() => handleMenuClick("faq")} label={t('Menu.faq')}/>,
          <MenuSubElement clickFunction={() => handleMenuClick("support")} label={t('Menu.Support')}/>,
        ]} icon={<Bulb size={25}/>} label={t('Menu.Help')}/>
        {(auth.currentUser && [adminEmail, "juleswimmer@gmail.com"].includes(auth.currentUser?.email)) && <MenuElement clickFunction={() => handleMenuClick("matching")} icon={<CheckmarkCircle size={25}/>} label="MATCHING"/>}
        <div className='grow'/>
        <Footer />
      </div>
    </Drawer>
    </div>
    </>
  )
};

const MenuElement = ({label, icon, animationIcon, clickFunction, anim = false, multi = false, menuElements = [], textColor = "default", hover = true, count = null}) => {

  const [isHover, setIsHover] = useState(false);
  const [isExtended, setIsExtended] = useState(false);

  const variants = {
    default: "",
    red: "text-red-600",
    orange: "text-orange-400",
  };

  return (
    <div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} onClick={multi ? () => setIsExtended(!isExtended) : () => clickFunction()} className={`flex flex-col justify-center w-full cursor-pointer select-none ${!hover ? 'hover:bg-orange-100' : ""}`}>
      <div className={`flex flex-row items-center ${anim ? 'gap-4' : 'gap-5'} ${isExtended ? 'bg-allimmoDark/20' : ''} w-full ${hover ? 'hover:bg-allimmoDark/20' : ''} py-2 md:py-2.5 pl-5 pr-3`}>
        {!anim && <div className={`${variants[textColor]}`}>{icon}</div>}
        {anim && <AnimationIcon _color={false} animate={isHover} _iconSize={32} iconSrc={animationIcon}/>}
        <div className={`text-lg relative flex flex-row grow gap-1 ${variants[textColor]}`}>
          <p>{label}</p>
          {count !== null && <p>{`(${count})`}</p>}
        </div>
        {multi && <ArrowRightSolid size={20} className='text-lg'/>}
      </div>
      {multi &&
      <Collapse className={`bg-allimmoDark/10 pb-1`} in={isExtended}>
        <div className='flex flex-col gap-1'>
          {menuElements.map((el, i) => { return <div key={i}>{el}</div> })}
        </div>
      </Collapse>}
    </div>
  )
};

const MenuSubElement = ({label, clickFunction}) => {
  return ( 
    <div onClick={clickFunction} className={`flex flex-row gap-5 pl-10 items-center hover:bg-slate-100 py-1`}>
      <DotOpen className='text-slate-600' size={15}/>
      <p className='text-lg relative flex flex-row grow'>{label}</p>
    </div>
  )
};

const AccountMenu = ({handleClick, logout}) => {

    const { handleMenuState, customer } = useStateContext();
    const [isExtended, setIsExtended] = useState(false);

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, () => {
      setIsExtended(false);
    });

    const handleSubClick = (newMenuState) => {
      setIsExtended(false);
      handleClick(newMenuState);
    };

    const handleLogoutClick = () => {
      setIsExtended(false);
      logout();
    };

    return (
      <div className='relative flex flex-row items-center gap-2'>
        <div className='flex flex-row items-center gap-3 justify-center'>
          {customer?.credits === undefined ? 
          <div className='flex flex-row space-x-2'>
            <CircularProgress sx={{color: "#00547B"}} size={15}/>
          </div> :
          <div  className={`flex flex-col items-end`}>
            <p className='grow text-center text-allimmoDark text-xs ssm:text-sm font-semibold -mb-1'>{ `CREDITS`}</p>
            <div onClick={() => handleMenuState("shop")} className='flex flex-row gap-2 cursor-pointer text-xs ssm:text-sm hover:underline text-allimmoDark'>
              {customer?.credits > 0 && <p>{customer?.credits.toLocaleString()}</p>}
              {customer?.credits === 0 && <p>{"ZUM SHOP"}</p>}
            </div>
          </div>}
          <Account onClick={() => setIsExtended(true)} size={40} className='text-orange-400 hover:text-red-400 cursor-pointer'/>
        </div>
        {isExtended &&
        <div ref={wrapperRef} className={`absolute top-12 -right-2 ssm:right-0 flex flex-col items-center justify-center bg-white shadow-lg rounded border`}>
          <div className='flex flex-row items-center justify-end w-full border-allimmoDark/20 p-2 gap-5 border-b bg-slate-50'>
            <p className='grow text-sm mt-1 italic pl-1 text-allimmoDark/40 min-w-200'>{auth.currentUser.email}</p>
            <CloseX onClick={() => setIsExtended(false)} size={32} className='self-end text-allimmoDark/40 font-thin -mr2 cursor-pointer hover:text-allimmoDark/80'/>
          </div>
          <MenuElement clickFunction={() => handleSubClick("account")} label={t('Menu.Account')} icon={<PersonOutline size={25} />}/>
          <MenuElement clickFunction={() => handleSubClick("shop")} icon={<Cart size={25}/>} label={t('Menu.Shop')}/>
          {/* <MenuElement clickFunction={() => handleSubClick("calender")} label={t('Menu.Calendar')} icon={<Calendar size={25}/>}/> */}
          {customer?.savedObjects?.length > 0 && <MenuElement count={customer?.savedObjects?.length} clickFunction={() => handleSubClick("saved")} label={t('Menu.Saved')} icon={<Heart size={25} />}/>}
          <MenuElement clickFunction={() => handleSubClick("updates")} label={t('Menu.Updates')} icon={<Upgrade size={25} />}/>
          <MenuElement clickFunction={() => handleSubClick("settings")} label={t('Menu.Einstellungen')} icon={<Settings size={25} />}/>
          <MenuElement clickFunction={() => handleSubClick("affiliate")} label={t('Menu.Affiliate')} icon={<Affiliate size={25}/>} />
          <MenuElement hover={false} textColor='orange' clickFunction={handleLogoutClick} label={t('General.Logout')} icon={<Logout size={25} />}/>
        </div>}
      </div>
    )
};

export default Topbar
