import { Checkbox, Dialog, FormControlLabel } from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';
import { useStateContext } from '../../../contexts/ContextProvider';
import { auth } from '../../../firebase.config';
import { Transition, acceptOffer, convertShortTimestamp } from '../../../helper/functions';
import AnimationIcon from '../../Generics/AnimationIcon';
import Button from '../../Generics/Button';
import { useSearchDetailsContext } from '../Contexts/SearchDetailsProvider';

const PendingOffers = () => {

    const { searchContacts } = useSearchDetailsContext();
    const { screenSize } = useStateContext();

    const pendingOffers = searchContacts?.filter(o => o.status === "pending");

  return (
    <div className={`${pendingOffers.length > 0 ? "block" : "hidden"} flex flex-col items-center justify-center w-full`}>
        <p className='text-14 w-full pl-0.5 ssm:pl-0'>{`${t('ObjectDetails.NeueAngebote')}:`}</p>
        <div className={`flex flex-col mt-1 w-full gap-2`}>
            {pendingOffers?.map((p, i) => {
            return (
                <PendingOffer key={i} data={p} index={i} />
            )
            })}
        </div>
    </div>
  )
};

const PendingOffer = ({data, index}) => {

    const { setIsLoading, customer, screenSize } = useStateContext();

    const [comissionContractOpen, setComissionContractOpen] = useState(false);
    const [contracts, setContracts] = useState({
        comission: false,
        privacy: false,
    });

    const handleOfferAccept = async () => {
        setComissionContractOpen(false);
        setIsLoading(true);
        await acceptOffer({contactID: data.id, customerID: data.customer, objectID: data.object, searchID: data.search, senderName: customer?.name, email: auth.currentUser.email})
        .catch(() => {

            setIsLoading(false);
        })
        setIsLoading(false);
    };

    const handleComissionContract = () => {
        if(data?.deal === "sale") {
            setContracts({
                comission: false,
                privacy: false,
              })
            setComissionContractOpen(true);
        } else {
            handleOfferAccept();
        }
    };

    return (
            <div onClick={handleComissionContract} className={`w-full flex flex-row items-center border shadow-lg border-allimmoDark border-dashed py-2 px-3 cursor-pointer rounded bg-white hover:bg-gray-50 gap-3`}>
                    {/* <p className={`border rounded-full text-center px-1.5 text-allimmoDark border-allimmoDark/80 font-bold text-sm`}>{index + 1}</p> */}
                    {/* <Dot size={20} className="text-orange-400" /> */}
                    <div className='flex flex-col mmd:flex-row ssm:items-center grow'>
                        <p className={`text-allimmoDark/80 font-semibold w-full`}>{data?.address}</p>
                        <p className='text-red-600 italic text-12 mmd:text-center opacity-60 w-full'>{`${t('SearchDetails.LäuftAb')} ${convertShortTimestamp(data.timeout?.toDate())}`}</p>
                    </div>
                    {screenSize.width > 480 && <p className='text-sm text-allimmoDark hover:bg-allimmoDark/10'>{t('SearchDetails.AcceptedOffer.OpenObject')}</p>}
            <Dialog className='select-none' open={comissionContractOpen} fullWidth={false} TransitionComponent={Transition}>
                <div className='flex flex-col justify-center items-center p-5 space-y-3'>
                    <p className='text-xl'>{t('Vereinbarungen.Header')}</p>
                </div>

                {data.privacyLink !== "" && 
                <div className='flex flex-row items-center pb-5 px-10 space-x-3'>
                    <FormControlLabel control={<Checkbox checked={contracts.privacy} value={contracts.privacy} onChange={() => setContracts({...contracts, privacy: !contracts.privacy})} disableRipple disableTouchRipple size="small"/>} label={t('Vereinbarungen.Datenschutzvereinbarung')} />
                    <a target='_blank' rel="noreferrer" href={data?.privacyLink} className='grow text-end hover:underline cursor-pointer text-allimmoDark/50 active:bg-allimmoDark/50 hover:text-allimmoDark'>{t('General.Ansehen')}</a>
                </div>}
                <p className='text-sm text-center px-10'>{t('Vereinbarungen.Erklärung')}</p>

                <a target='_blank' rel="noreferrer" href={data?.comissionContractLink} className='hover:underline w-full text-center mt-5 cursor-pointer text-allimmoDark/50 active:bg-allimmoDark/50 hover:text-allimmoDark'>{t('Vereinbarungen.Provisionsvereinbarung')}</a>


                <div className='flex flex-row justify-center items-center space-x-2 my-5'>
                    <Button text={t('General.Abbrechen')} clickFunction={() => setComissionContractOpen(false)}/>
                    <Button text={t('Vereinbarungen.Bestätigen')} clickFunction={handleOfferAccept}/>
                </div>
            </Dialog>
        </div>
    )
};

export default PendingOffers
