import { Checkbox, CircularProgress, Collapse, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import { addCredits, deleteUserData, getCustomerEmails, globalMatching, searchCleaning, sendUpdateEmail, userCreateAdmin } from '../helper/functions';
import AdminMenu from '../components/Admin/AdminMenu';
import { useAdminContext } from '../components/Admin/Contexts/AdminProvider';
import DeleteUserSearches from '../components/Admin/Components/DeleteUserSearches';
import Home from '../components/Admin/Components/Home';
import SingleUserSummary from '../components/Admin/Components/SingleUserSummary';

const Admin = () => {
    const { adminEmail } = useStateContext();
    const { selectedMenu, selectedSub } = useAdminContext();

    const memeberEmails = [adminEmail, "vivpour@gmail.com", "vp@allimmo.at", "juleswimmer@gmail.com", "joshuakrick@hotmail.de"];
    const initExtendedTools = {
        email: false,
        credits: false,
        update: false,
        delete: false,
        createUser: false,
        searchCleaning: false,
        globalMatching: false,
    };

    const deleteOptions = {
        userData: true,
        searches: true,
        chats: true,
    }

    const [withAdminEmails, setWithAdminEmails] = useState(false);
    const [emalList, setEmalList] = useState([]);
    const [isLoadingEmails, setIsLoadingEmails] = useState(false);
    const [isLoadingAddCredits, setIsLoadingAddCredits] = useState(false);
    const [updateEmailLoading, setUpdateEmailLoading] = useState(false);
    const [extendedTools, setExtendedTools] = useState(initExtendedTools);
    const [creditData, setCreditData] = useState({
        email: "",
        amount: 0,
    });

    const [createUserData, setCreateUserData] = useState({
        name: "",
        email: "",
        phone: "",
        password: "",
        credits: "2",
    })
    const [addedData, setAddedData] = useState({});
    const [infoText, setInfoText] = useState("");
    const [templateId, setTemplateId] = useState("");
    const [updateText, setUpdateText] = useState("");  
    
    const [deleteEmail, setDeleteEmail] = useState("");
    const [deleteText, setDeleteText] = useState("");
    const [deleteLoading, setDeleteLoading] = useState(false);

    const getEmails = async () => {
        setIsLoadingEmails(true);
        const emails = await getCustomerEmails({adminEmails: withAdminEmails ? ["123"] : [...memeberEmails]});
        if(emails) {
            setIsLoadingEmails(false);
            setEmalList(emails.data);
        }
    };

    const extendTool = (tool) => {
        setExtendedTools({...initExtendedTools, [tool]: !extendedTools[tool]})
    };

    const addUserCredits = async () => {
        if(parseInt(creditData.amount) === 0 || creditData.email.trim().length === 0 || creditData.amount.toString().trim().length === 0) return setInfoText("input");
        
        setIsLoadingAddCredits(true);
        setInfoText("");
        setAddedData({...creditData});
        
        await addCredits({email: creditData.email, amount: parseInt(creditData.amount)})
        .then((res) => {
            if(res.data === "success") {
                setInfoText("success");
                setCreditData({email: "", amount: 0});
            }

            if(res.data === "error") {
                setInfoText("error");
            }

            if(res.data === "input") {
                setInfoText("input");
            }
            setIsLoadingAddCredits(false);
        })
        .catch((err) => {
            console.log(err)
            setIsLoadingAddCredits(false);
        })
    };

    const sendUpdateEmaill = async () => {
        if(templateId.trim().length !== 34) return;
        if(!templateId.trim().startsWith("d-")) return;
        setUpdateEmailLoading(true);

        await sendUpdateEmail({templateId: templateId})
        .then((res) => {
            setTemplateId("");
            setUpdateText(`${res.data.amount} Erfolgreich | ${res.data.error} Fehler`);
        })
        .catch((err) => {
            setUpdateText("Email konnte nicht gesendet werden!", err.message);
        });
        setUpdateEmailLoading(false);
    };

    const deleteUser = async () => {
        if(deleteEmail.trim() === adminEmail) return setDeleteText("Allimmo Account kann nicht gelöscht werden!");
        setDeleteLoading(true);
        await deleteUserData({email: deleteEmail.trim(), options: deleteOptions})
        .then((res) => {
            setDeleteText(res.data);
            if(res.data === "success") {
                setDeleteEmail("");
            }
        });
        setDeleteLoading(false);
    };

    const createUserAdmin = async () => {
        // if (Object.values(createUserData).some((v) => v.trim().length === 0)) return;
        console.log(createUserData);
        await userCreateAdmin({...createUserData, emailVerified: true});
    };

  return (
    <div className='flex flex-col w-full mx-10 mt-5'>
        <p className='text-xs italic w-full text-end mb-0.5 font-thin text-allimmoDark opacity-20'>admin-dashboard v0.0.1</p>
        <AdminMenu />
        {selectedMenu === "main" && <Home />}
        {selectedSub === "deleteSearches" && <DeleteUserSearches />}
        {selectedSub === "singleUserSummary" && <SingleUserSummary />}
    </div>
  )
}

export default Admin
