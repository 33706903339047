import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { ContextProvider } from './contexts/ContextProvider';
import { CreationProvider } from './contexts/CreationProvider';
import { DeletionProvider } from './contexts/DeletionProvider';
import { PopupProvider } from './contexts/PopupProvider';
import { SelectionProvider } from './contexts/SelectionProvider';
import './i18n';
import './index.css';
import { LoadingProvider } from './contexts/LoadingProvider';
import { ObjectsProvider } from './components/Object/Contexts/ObjectsProvider';
import { SearchesProvider } from './contexts/SearchesProvider';
import { DatabaseCountsProvider } from './contexts/DatabaseCountsProvider';
import { AdminProvider } from './components/Admin/Contexts/AdminProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <DatabaseCountsProvider>
        <ObjectsProvider>
          <SearchesProvider>
            <ContextProvider>
              <CreationProvider>
                <SelectionProvider>
                  <DeletionProvider>
                    <PopupProvider>
                      <AdminProvider>
                        <LoadingProvider>
                          <div className='h-screen'>
                            <App />
                          </div>
                        </LoadingProvider>
                      </AdminProvider>
                    </PopupProvider>
                  </DeletionProvider>
                </SelectionProvider>
              </CreationProvider>
            </ContextProvider>
          </SearchesProvider>
        </ObjectsProvider>
      </DatabaseCountsProvider>
    </BrowserRouter>
  </React.StrictMode>
);
