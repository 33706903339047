import { collection, onSnapshot, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useSearchesContext } from '../contexts/SearchesProvider';
import { useSelectionContext } from '../contexts/SelectionProvider';
import { auth, firestore } from '../firebase.config';
import { Plus } from '../helper/icons';
import { Collapse } from '@mui/material';
import SearchMenu from '../components/SearchDetails/SearchMenu';
import BaseInfos from '../components/SearchDetails/BaseInfos';
import { t } from 'i18next';
import { Circle, MapContainer, Polygon, TileLayer } from 'react-leaflet';
import Facilities from '../components/SearchDetails/Facilities';
import { facilities } from '../helper/arrays';
import Commercial from '../components/SearchDetails/Commercial';
import Condition from '../components/SearchDetails/Condition';
import OpenAreas from '../components/SearchDetails/OpenAreas';
import Vacancy from '../components/SearchDetails/Vacancy';
import PendingOffers from '../components/SearchDetails/Components/PendingOffers';
import AcceptedOffers from '../components/SearchDetails/Components/AcceptedOffers';
import { useSearchDetailsContext } from '../components/SearchDetails/Contexts/SearchDetailsProvider';

const SearchDetails = () => {
    const { selectedSearchID } = useSelectionContext();
    const { customerSearches } = useSearchesContext();
    const { searchContacts } = useSearchDetailsContext();

    const searchSelection = customerSearches.find(search => search.id === selectedSearchID);
    const hasValidFacilities= facilities.filter(f => f.usage.includes(searchSelection?.type) && searchSelection[f.name]).length > 0;
    const timeoutContacts = searchContacts?.filter(e => e.status === "timeout").length;

    const [expanded, setExpanded] = useState({info: false});
    const [contacts, setContacts] = useState();

    useEffect(() => {
      if(searchSelection === undefined) return;
      const contactsRef = query(collection(firestore, "contacts"), where("search", "==", searchSelection?.id), where("customer", "==", auth.currentUser.uid));
      onSnapshot(contactsRef, (result) => {
        setContacts(result.docs.map((doc) => ({...doc.data(), id: doc.id })));
      });
      // eslint-disable-next-line
    }, []);

    const getZoom = () => {
      const p = searchSelection?.polygon[0];
      // if(p?.type === "polygon") {
      //   const size = L.GeometryUtil.geodesicArea(p?.coordinates) / 1000;
      //   return size < 50 ? 17 : size < 100 ? 16 : size < 500 ? 15 : size < 1000 ? 14 : size < 10000 ? 13 : size < 70000 ? 12 : size < 350000 ? 11 : 10;
      // }
      if(p?.type === "circle"){
        return p?.radius < 500 ? 15 : p?.radius < 1000 ? 14 : p?.radius < 3000 ? 13 : p?.radius < 5500 ? 12 : p?.radius < 13000 ? 11 : p?.radius < 25000 ? 10 : p?.radius < 50000 ? 9 : p?.radius < 105000 ? 8 : 7;
      }

      return 10;
  };

  return (
      <div className={`flex flex-col items-center w-full px-1 ssm:px-2 pb-10`}>
        {searchSelection ?
        <div className='flex flex-col items-center w-full mdd:w-1000'>
          <SearchMenu searchData={searchSelection} />
          {/* Search Info */}
          <div className={`flex flex-col justify-center w-full border rounded border-allimmoDark mt-1 relative bg-allimmoDark/5`}>
            <div onClick={() => setExpanded({...expanded, info: !expanded.info})} className='flex flex-row w-full justify-start items-center pt-2 px-2'>
              <Plus size={17} className={`cursor-pointer text-allimmoDark hover:text-slate-400 mr-2`}/>
              <p className='grow cursor-pointer active:text-allimmoDark truncate'>{`${searchSelection?.name}`}</p>
            </div>
            <Collapse in={expanded.info} className='flex flex-col w-full px-2 pb-2'>
              <p className='text-lg mb-1'>{`${t(`SearchDetails.Types.${searchSelection?.type}`)} - ${t(`Deals.${searchSelection?.deal}`)}`}</p>
              <div className='flex flex-row gap-2 items-center -mt-2 text-13 text-orange-400'>
                {searchSelection?.searchOrder && 
                <p className='italic'>{t('SearchDetails.SearchOrder')}</p>}
                {(searchSelection?.searchOrder && searchSelection?.urgency) && <p>{"|"}</p>}
                {searchSelection?.urgency === "urgent" && 
                <p className='italic'>{t('SearchDetails.UrgentSearch')}</p>}
              </div>
              
                <div className='flex flex-col md:flex-row gap-2 mt-2 justify-between w-full'>
                  <BaseInfos _searchData={searchSelection} />
                  <div className='flex flex-col gap-2 w-full'>
                    {/* Location */}
                    {searchSelection?.polygon !== undefined &&
                    <div className='bg-white rounded-t flex flex-col w-full border z-0 border-allimmoDark/70'>
                      <div className="w-full flex flex-row justify-between items-center bg-slate-200 px-1.5 py-0.5 rounded-t">
                        <p>{searchSelection?.polygon.length > 1 ? t('SearchDetails.Location.HeaderMultiple') : t('SearchDetails.Location.Header')}</p>
                        {/* {_objectData?.type !== "land" && 
                        <Menu objectData={_objectData} _setPropToAdd={setPropToAdd}/>} */}
                      </div>
                      <div className='w-full h-300'>
                        <MapContainer center={searchSelection?.polygon[0].type === "circle" ? searchSelection?.polygon[0].coordinates : searchSelection?.polygon[0].center} zoom={getZoom()} zoomControl={false}>
                            <TileLayer
                            attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <div>
                            {searchSelection?.polygon.map((p, i) => {
                                return(
                                <div key={i}>
                                    {p?.type === "circle" && <Circle radius={p?.radius} center={p?.coordinates}/>}
                                    {p?.type === "polygon" && <Polygon positions={p?.coordinates}/>}
                                </div>
                                )
                            })}
                            </div>
                        </MapContainer>
                      </div>
                    </div>}
                    {(searchSelection?.first === true || searchSelection?.new === true || searchSelection?.renovation === true) &&
                    <Condition searchData={searchSelection} />}
                  </div>
                </div>
                <div className={`flex flex-col md:flex-row gap-2 mt-2 ${(hasValidFacilities || searchSelection?.type !== "commercial") ? "block" : "hidden"}`}>
                  {hasValidFacilities && <Facilities searchData={searchSelection} />}
                  {searchSelection?.type === "commercial" && <Commercial searchData={searchSelection} />}
                </div>
                <div className={`flex flex-col md:flex-row gap-2 mt-2`}>
                  {searchSelection?.openAreas !== "-" && 
                  <OpenAreas searchData={searchSelection} />}
                  {(searchSelection?.vacant === true || searchSelection?.limited === true || searchSelection?.unlimited === true) &&
                  <Vacancy searchData={searchSelection} />}
                </div>
                <p className='italic text-xs select-text mt-1.5 ml-0.5'>{"id: " + searchSelection?.id}</p>
            </Collapse>
          </div>
          
        </div> : 
        <p className='mt-5 text-center px-3 text-allimmoDark'>{t('SearchDetails.NoData')}</p>}
        {searchSelection && 
        <div className='flex flex-col gap-5 mt-5 w-full mdd:w-1000'>
            <PendingOffers />
            <AcceptedOffers />
            {timeoutContacts > 0 &&
            <p className='text-14 w-full'>{`${t('SearchDetails.TimeoutAngebote')} ${timeoutContacts}`}</p>}
          </div>}
      </div>
  )
};

export default SearchDetails
