import { Collapse } from "@mui/material";
import { useAdminContext } from "./Contexts/AdminProvider";

const AdminMenu = () => {

    const {selectedMenu, selectedSub, setSelectedMenu, setSelectedSub} = useAdminContext();

    const AdminMenuItem = ({icon, label, clickFuction, selected}) => {
        return (
            <div onClick={clickFuction} className={`flex flex-col items-center justify-center cursor-pointer ${selected ? "bg-allimmoDark/10" : "hover:bg-allimmoDark/5"} py-2 px-3`}>
                <p className='text-allimmoDark'>{label}</p>
            </div>
        )
    };

    const SubMenuItem = ({icon, label, clickFuction, selected}) => {
        return (
            <div onClick={clickFuction} className={`flex flex-col items-center justify-center cursor-pointer ${selected ? "bg-blue-200" : "hover:bg-blue-300"} py-2 px-3`}>
                <p className='text-allimmoDark text-xs italic'>{label}</p>
            </div>
        )
    };

    const handleMenuClick = (item) => {
        if (item !== selectedMenu) setSelectedSub("");
        setSelectedMenu(item);
    };

    const handleSubMenuClick = (item) => {
        setSelectedSub(item);
    };

    return (
        <div className={`border-y border-allimmoDark/50 w-fill flex flex-col`}>
            <div className={`border-y border-allimmoDark/50 w-fill flex flex-row items-center`}>
                <AdminMenuItem label='Home' selected={selectedMenu === "main"} clickFuction={() => handleMenuClick("main")}/>
                <AdminMenuItem label='User' selected={selectedMenu === "user"} clickFuction={() => handleMenuClick("user")}/>
                <AdminMenuItem label='Searches' selected={selectedMenu === "searches"} clickFuction={() => handleMenuClick("searches")}/>
                {/* <AdminMenuItem label='Matching' selected={selectedMenu === "matching"} clickFuction={() => handleMenuClick("matching")}/> */}
            </div>
            <Collapse className="border-b border-allimmoDark/50" in={selectedMenu !== "main"}>
                <div className={`flex flex-row items-center ${selectedMenu === "user" ? "block" : "hidden"}`}>
                    <SubMenuItem label='Single User Summary' selected={selectedSub === "singleUserSummary"} clickFuction={() => handleSubMenuClick("singleUserSummary")}/>
                </div>
                <div className={`flex flex-row items-center ${selectedMenu === "searches" ? "block" : "hidden"}`}>
                    <SubMenuItem label='Deactivate Searches' selected={selectedSub === "deleteSearches"} clickFuction={() => handleSubMenuClick("deleteSearches")}/>
                    {/* <SubMenuItem label='Find Search' selected={selectedSub === "findSearch"} clickFuction={() => handleSubMenuClick("findSearch")}/> */}
                </div>
                <div className={`flex flex-row items-center ${selectedMenu === "matching" ? "block" : "hidden"}`}>
                    <SubMenuItem label='Match Account Objects' selected={selectedSub === "accountObjectMatching"} clickFuction={() => handleSubMenuClick("accountObjectMatching")}/>
                </div>
            </Collapse>
        </div>
    )
}

export default AdminMenu
