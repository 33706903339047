import { CircularProgress } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import { useSearchesContext } from "../../../contexts/SearchesProvider";
import { useSelectionContext } from "../../../contexts/SelectionProvider";
import { useSearchDetailsContext } from "../Contexts/SearchDetailsProvider";
import { auth, firestore } from "../../../firebase.config";
import { doc, onSnapshot } from "firebase/firestore";
import { encryptString, updateFirestoreDoc } from "../../../helper/functions";
import { Delete, Dot, Edit, EditPen } from "../../../helper/icons";

    const AcceptedOffers = () => {

        const { searchContacts } = useSearchDetailsContext();
        const { screenSize } = useStateContext();

        const acceptedOffers = searchContacts?.filter(o => ["accepted", "deleted"].includes(o.status));

        return (
            <div className={`${acceptedOffers?.length > 0 ? "block" : "hidden"} flex flex-col items-center justify-center w-full`}>
                <p className='text-14 w-full pl-0.5 ssm:pl-0'>{`${t('SearchDetails.AkzeptierteAngebote')}: ${acceptedOffers?.length}`}</p>
                {/* <div className={`grid mt-1 ${screenSize.width > 800 ? "grid-cols-5" : screenSize.width > 640 ? "grid-cols-4" : screenSize.width > 450 ? "grid-cols-3" : "grid-cols-2"} w-full gap-2`}> */}
                <div className={`flex flex-col mt-1 w-full gap-2`}>
                    {acceptedOffers?.map((p, i) => {
                    return (
                        <>
                            {p.status === "accepted" && <AcceptedOffer key={i} data={p} index={i} />}
                            {p.status === "deleted" && <DeletedOffer key={i} data={p} index={i} />}
                        </>
                    )
                    })}
                </div>
            </div>
        )
    };

    const AcceptedOffer = ({data, index}) => {

        const { setChatSelection, setChatDetailsOpen, selectedSearchID } = useSelectionContext();
        const { setInfoData, setInfoOpen, screenSize } = useStateContext();
        const { customerSearches } = useSearchesContext();
    
        const [offeredObject, setOfferedObject] = useState({});
        
        useEffect(() => {
            const offeredObjectRef = doc(firestore, "objects", data.object);
            onSnapshot(offeredObjectRef, (result) => {
              setOfferedObject({
                    edited: result.data().edited, 
                    id: result.id,
                });
            });
            // eslint-disable-next-line
        }, []);

        const searchData = customerSearches.find(search => search.id === selectedSearchID);
    
        const handleChatOpen = (e) => {
            e.stopPropagation();
            setChatSelection(data?.chat);
            setChatDetailsOpen(true);
        };
    
        const handleEditedInfo = (e) => {
            e.stopPropagation();
            setInfoData({header: t("SearchDetails.EditedInfoHeader"), text: t("SearchDetails.EditedInfoText")});
            setInfoOpen(true);
        };

        const getData = async () => {
                                          
        };
    
        const editedSinceMatch = () => {
            const edited = offeredObject?.edited?.toDate();
            const matched = data?.created?.toDate();
            return (matched - edited) < 0;
        };
    
        const handleObjectListingOpen = () => {
            window.open(`/objects/${data.object}?id=${encryptString(selectedSearchID)}&search=${encryptString(searchData?.name)}`, "_blank");
        };
    
        return (
            <div onClick={handleObjectListingOpen} className="w-full flex flex-col border shadow-lg border-allimmoDark border-dashed py-2 px-3 cursor-pointer rounded bg-white">
                <div className="flex flex-row items-center gap-2 text-allimmoDark">
                    {/* <p className={`border rounded-full text-center px-1.5 text-allimmoDark border-allimmoDark/80 font-bold text-sm`}>{index + 1}</p> */}
                    {/* <Dot size={20} className="text-orange-400" /> */}
                    <p className={`text-allimmoDark/80 text-sx font-semibold grow`}>{data?.address}</p>
                    {screenSize.width > 550 && <p className='text-sm hover:bg-allimmoDark/10'>{t('SearchDetails.AcceptedOffer.OpenObject')}</p>}
                    <div onClick={(e) => data?.chat !== "" ? handleChatOpen(e) : null} className={`flex flex-col items-center justify-center ${data?.chat !== "" ? "hover:bg-allimmoDark/10" : ""}`}>
                            {data?.chat === "" ? <CircularProgress size={20}/> : <p className='text-sm px-1'>{t('SearchDetails.AcceptedOffer.OpenChat')}</p>}
                    </div>
                </div>
                <div className="flex flex-row items-center justify-between mt-1">
                    {editedSinceMatch() &&
                    <div className="flex flex-row items-center justify-center gap-2 border border-allimmoDark rounded bg-allimmoDark/50 text-white px-2 py-1 hover:bg-white hover:text-allimmoDark">
                        <EditPen  size={14} />
                        <p onClick={(e) => handleEditedInfo(e)} className='italic text-xs'>{t('SearchDetails.Bearbeitet')}</p>
                    </div>}
                </div>
            </div>
        )
    };

    const DeletedOffer = ({data, index}) => {

        const skipStatusInfo = async () => {
            if(data.status !== "deleted") return;
            if(data.searchUserId !== auth.currentUser.uid) return;

            return await updateFirestoreDoc("contacts", data.id, {status: "skipped"});
        };

        return (
            <div className="flex flex-row justify-between">
                <p>{data.address}</p>
                <p>OBJEKT WURDE GELÖSCHT</p>
                <Delete onClick={() => skipStatusInfo()} />
            </div>
        )
    };
    
    export default AcceptedOffers
    