import { CircularProgress, Dialog } from '@mui/material';
import { sendPasswordResetEmail } from 'firebase/auth';
import { collection, getDocs, getDocsFromServer, query, where } from 'firebase/firestore';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import Username from '../components/Account/Username';
import Button from '../components/Generics/Button';
import AnimationIcon from "../components/Generics/AnimationIcon.jsx";
import { auth, firestore } from '../firebase.config';
import { Transition, convertShortTimestamp, updateFirestoreDoc } from '../helper/functions';
import { useStateContext } from '../contexts/ContextProvider';
import { Check, Education, Income, StarBorn } from '../helper/icons.js';
import validator from 'validator';
import useOutsideClick from '../hooks/useOutsideClick.jsx';

const Account = () => {

  const { customer } = useStateContext();

  const [paymentsOpen, setPaymentsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isResetLoading, setIsResetLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [payments, setPayments] = useState([]);
  const [genderLoading, setGenderLoading] = useState(false);
  const [incomeLoading, setIncomeLoading] = useState(false);
  const [educationLoading, setEducationLoading] = useState(false);
  const [yearLoading, setYearLoading] = useState(false);
  const [yearEditMode, setYearEditMode] = useState(false);
  const [newYear, setNewYear] = useState(customer?.yearOfBirth);

  useEffect(() => {
    if(emailSent) {
      setTimeout(() => {
        setEmailSent(false);
      }, "5000");
    }
    // eslint-disable-next-line
  }, [emailSent]);

  const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, () => {
      setYearEditMode(false);
  });

  const getPayments = async () => {
    setPaymentsOpen(true);
    setIsLoading(true);
    const paymentsQuery = query(collection(firestore, "payments"), where("client_reference_id", "==", auth.currentUser.uid));
    const querySnapshot = await getDocsFromServer(paymentsQuery);
    setPayments(querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id })));
    setIsLoading(false);
  };

  const changeGender = async () => {
    setGenderLoading(false);
    const genderOptions = [
      "male",
      "female",
      "divers",
      "-"
    ];

    const currentGender = customer?.gender ?? "-";
    const currentIndex = genderOptions?.indexOf(currentGender);
    
    const newGender = genderOptions[currentIndex === genderOptions.length - 1 ? 0 : currentIndex + 1];
    
    try {
      await updateFirestoreDoc("customers", auth.currentUser.uid, {gender: newGender});
      setGenderLoading(false);
      return true;
    } catch (error) {
      console.error("Error updating document: ", error.message);
      setGenderLoading(false);
      return false;
    }
  };

  const changeIncome = async () => {
    setIncomeLoading(false);
    const incomeOptions = [
      "1m",
      "13",
      "35",
      "5p",
      "-"
    ];

    const currentIncome = customer?.income ?? "-";
    const currentIndex = incomeOptions?.indexOf(currentIncome);
    
    const newIncome = incomeOptions[currentIndex === incomeOptions.length - 1 ? 0 : currentIndex + 1];
    
    try {
      await updateFirestoreDoc("customers", auth.currentUser.uid, {income: newIncome});
      setIncomeLoading(false);
      return true;
    } catch (error) {
      console.error("Error updating document: ", error.message);
      setIncomeLoading(false);
      return false;
    }
  };

  const changeEducation = async () => {
    setEducationLoading(false);
    const educationOptions = [
      "degree",
      "matura",
      "lehre",
      "special",
      "-"
    ];

    const currentEducation = customer?.education ?? "-";
    const currentIndex = educationOptions?.indexOf(currentEducation);
    
    const newEducation = educationOptions[currentIndex === educationOptions.length - 1 ? 0 : currentIndex + 1];
    
    try {
      await updateFirestoreDoc("customers", auth.currentUser.uid, {education: newEducation});
      setEducationLoading(false);
      return true;
    } catch (error) {
      console.error("Error updating document: ", error.message);
      setEducationLoading(false);
      return false;
    }
  };

  const handleEnterPress = (event) => {
    if (event.keyCode === 13 || event.which === 13) {
      ChangeYear(event, true);
    }
  };

  const ChangeYear = async (e, isEnterPress = false) => {
    if(!isEnterPress) e.stopPropagation();
    if(!validator.isNumeric(newYear) || newYear.length != 4) return "invalid";
    
    setYearEditMode(false);
    setYearLoading(true);
    try {
      await updateFirestoreDoc("customers", auth.currentUser.uid, {yearOfBirth: newYear});
      setYearLoading(false);
      return "success";
    } catch (error) {
      setYearLoading(false);
      setYearEditMode(true);
      console.error("Error updating document: ", error.message);
      return "error";
    }
  };

  const sendPasswordReset = () => {
    setIsResetLoading(true);
    sendPasswordResetEmail(auth, auth.currentUser.email)
    .then(() => {
      setEmailSent(true);
      setIsResetLoading(false);
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
  });
  };

  return (
    <div className={`flex flex-col items-center w-screen mt-26`}>
        <div className={`border-allimmoDark rounded-full h-32 aspect-square flex flex-row justify-center items-center`}>
            <AnimationIcon _iconSize={150} _color={false} iconSrc={require('../assets/animations/avatar.json')}/>
        </div>
        <div className='flex flex-col justify-center items-center'>
        <p className='text-3xl text-allimmoDark'>{customer?.name.trim() === "" ? t('Account.KeinUsername') : customer?.name}</p>
          {customer?.company && <p className='text-14 italic -mt-1.5'>{customer?.company ? t("Kontotypen.Firma") : t("Kontotypen.Privat")}</p>}
          <p className='text-lg italic mt-5'>{auth.currentUser.email}</p>
          {(customer?.phone && customer?.phone !== "") && <p className='text-14'>{customer?.phone}</p>}
        </div>
        <p className='text-sm'>{`${t('General.erstellt')} am ${convertShortTimestamp(auth.currentUser.metadata.creationTime)}`}</p>
        <p className='italic text-11 select-text text-slate-400'>{`id: ${auth.currentUser.uid}`}</p>

        <div className={`mt-8 flex flex-col smd:flex-row gap-1 smd:gap-2 text-allimmoDark justify-center cursor-pointer items-center`}>
            <div onClick={!genderLoading ? changeGender : null} className={`rounded h-14 w-120 flex flex-col justify-center cursor-pointer hover:bg-allimmoDark/5 items-center bg-white shadow-lg border border-allimmoDark/20 px-2 py-1 ${genderLoading ? "opacity-50" : ""}`}>
              <p className='text-xs font-bold -mb-1 opacity-70'>{t('Account.Geschlecht')}</p>
              <p className='text-lg font-thin'>{t(`Gender.${customer?.gender}`)}</p>
            </div>

            <div ref={wrapperRef} onClick={() => setYearEditMode(true)} className='rounded h-14 text-allimmoDark w-120 flex flex-col justify-center cursor-pointer hover:bg-allimmoDark/5 items-center bg-white shadow-lg border border-allimmoDark/20 px-2 py-1'>
              <p className='text-xs font-bold -mb-1 opacity-70'>{t('Account.Geburtsjahr')}</p>
              {(!yearEditMode && !yearLoading) && <p className='text-lg font-thin'>{customer?.yearOfBirth}</p>}
              {(yearEditMode && !yearLoading) && 
              <div className='flex flex-row items-center gap-1 mt-1'>
                <input onKeyDown={(e) => handleEnterPress(e)} autoFocus className='w-full grow text-center' type="number" value={newYear} onChange={(e) => setNewYear(e.target.value)}/>
                {(newYear.length === 4 && validator.isNumeric(newYear) && (newYear !== customer?.yearOfBirth)) && <Check onClick={(e) => ChangeYear(e)} className='text-green-600 font-bold cursor-pointer' size={18} />}
              </div>}
              {yearLoading && <CircularProgress className='mt-2' size={20} />}
            </div>
        </div>

        <div className='mt-1 smd:mt-2 flex flex-col smd:flex-row gap-1 smd:gap-2 justify-center cursor-pointer items-center'>
            <div onClick={!educationLoading ? changeEducation : null} className='rounded text-allimmoDark w-150 flex flex-col justify-center hover:bg-allimmoDark/5 items-center bg-white shadow-lg border border-allimmoDark/20 px-2 py-1'>
              <p className='text-xs font-bold -mb-1 opacity-70'>{t('Account.Ausbildung')}</p>
              <p className='text-lg font-thin'>{t(`Ausbildung.${customer?.education}`)}</p>
            </div>

            <div onClick={!incomeLoading ? changeIncome : null} className='rounded w-150 flex flex-col justify-center cursor-pointer hover:bg-allimmoDark/5 text-allimmoDark items-center bg-white shadow-lg border border-allimmoDark/20 px-2 py-1'>
              <p className='text-xs font-bold -mb-1 opacity-70'>{t('Account.Nettoeinkommen')}</p>
              <p className='text-lg font-thin'>{t(`Income.${customer?.income}`)}</p>
            </div>
        </div>

        <p className='italic text-allimmoDark text-xs mt-1'>{t('Account.ÄndernKlicken')}</p>

        <div className='flex flex-row justify-center items-center space-x-2 mt-12'>
            <Button clickFunction={getPayments} text={t('Account.ZahlungenAnsehen')}/>
            {/* {auth.currentUser.providerData[0].providerId !== "password" && <Button text={"TEXT"} clickFunction={sendPasswordReset} loading={isResetLoading || emailSent}/>} */}
        </div>

        <p className='text-allimmoDark italic text-sm text-center px-2 sm:px-5 mt-3'>{t('Account.Löschen')}</p>

        {(emailSent && !isResetLoading) &&
        <div className='flex flex-col justify-center items-center'>
          <p className='text-green-500 text-14 mt-5'>{t('Account.ResetEmailSent')}</p>
          <p className='text-green-500 text-14'>{auth.currentUser.email}</p>
        </div>}

        <Dialog open={paymentsOpen} fullWidth={true} TransitionComponent={Transition}>
          <div className='p-2 ssm:p-5 flex flex-col justify-center items-center'>
            <p className='mb-5 text-lg ssm:text-xl'>{t('Account.MeineZahlungen')}</p>
            {isLoading ? <CircularProgress size={50} /> :
            payments.length === 0 ? 
            <p className='mb-5'>{t('Account.KeineZahlungen')}</p> :
            payments.map((p,i) => {
              return (
                <div key={i} className='flex flex-row items-center bg-slate-50 px-2 py-0.5 w-full space-x-3 ssm:space-x-5 text-xs ssm:text-base'>
                  <p>{i + 1}.</p>
                  <p className='grow'>{`${(p.amount_total/100).toFixed(2)}€ (+${p.credits} Credits)`}</p>
                  <p className='text-end'>{new Date(p.created * 1000).toLocaleString()}</p>
                </div>
              )
            })}

            <div className='mt-5'>
              <Button text="OK" clickFunction={() => setPaymentsOpen(false)}/>
            </div>
          </div>
        </Dialog>
    </div>
  )
};

export default Account
