import { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material';
import { convertLongTimestamp, getUserSummary } from '../../../helper/functions';
import validator from 'validator';

const SingleUserSummary = () => {

    const [inputData, setInputData] = useState({
        userID: "",
        email: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [userSummary, setUserSummary] = useState();

    const isButtonDisabled = (inputData.userID.trim() === "" && inputData.email.trim() === "") || (inputData.email.trim().length > 0 && !validator.isEmail(inputData.email));

    useEffect(() => {
        if(userSummary?.message === true) {
            setInputData({
                userID: "",
                email: "",
            });
            
            console.log(userSummary);
        }
        setIsLoading(false);
    }, [userSummary]);    

    const loadUserSummary = async () => {
        setIsLoading(true);
        try {
            const result = await getUserSummary({userId: inputData.userID, email: inputData.email});
            setUserSummary(result.data);
        } catch (error) {
            console.log(error.message);
        }
        return;
    };

    return (
        <div className='w-full flex flex-col justify-center items-center'>
            {(!userSummary && !isLoading) && <p className='italic text-s mt-12 mb-10 w-600 text-center'>Hier bekommst du nach Eingabe der Email Adresse oder der User ID eine Zusammenfassung des Users.</p>}

            {(!userSummary && !isLoading) &&
            <div className={`w-full flex flex-col justify-center items-center ${isLoading ? "hidden" : "block"}`}>
                <input disabled={inputData.email !== ""} value={inputData.userID} onChange={(e) => setInputData({...inputData, userID: e.target.value})} type="text" placeholder="UserID eingeben..." className={`w-300 text-center p-1 border rounded border-allimmoDark`}/>
                <p className='italic my-3 font-semibold'>ODER</p>
                <input disabled={inputData.userID !== ""} value={inputData.email} onChange={(e) => setInputData({...inputData, email: e.target.value})} type="text" placeholder="Email-Adresse eingeben..." className={`w-300 text-center p-1 border rounded border-allimmoDark`}/>
            </div>}

            {(userSummary && !isLoading && userSummary.message) &&
            <div className='flex flex-col items-center justify-center mt-10 w-full'>
                <p className='text-2xl underline'>{userSummary.customerData.name}</p>
                <p className='italic text-xs'>{userSummary.userId}</p>
                <p className='italic text-xs'>{userSummary.customerData.email}</p>
                <p className='italic text-xs'>Letzter login: {userSummary?.lastLogin}</p>
                <p className='italic text-xs'>Credits: {userSummary?.customerData?.credits}</p>
                
                <div className='flex flex-row gap-3 justify-center items-center'>
                    <div className='w-180 shadow-lg flex flex-col bg-white justify-center items-center gap-1 text-sm border border-dashed border-opacity-50 border-allimmoDark rounded py-2 px-8  mt-5'>
                        <p className='text-lg'>Objekte</p>
                        <div className='flex flex-row gap-3 text-sm italic'>
                            <p className='font-bold text-allimmoDark'>{userSummary.objects}</p>
                            <p className='text-green-500 opacity-70'>{userSummary.activeObj ?? 0}</p>
                            <p className='text-orange-500 opacity-70'>{userSummary.inactiveObj ?? 0}</p>
                            <p className='text-red-500 opacity-70'>{userSummary.deletedObj ?? 0}</p>
                        </div>
                    </div>

                    <div className='w-180 shadow-lg flex flex-col bg-white justify-center items-center gap-1 text-sm border border-dashed border-opacity-50 border-allimmoDark rounded py-2 px-8  mt-5'>
                        <p className='text-lg'>Suchen</p>
                        <div className='flex flex-row gap-3 text-sm italic'>
                            <p className='font-bold text-allimmoDark'>{userSummary.searches}</p>
                        </div>
                    </div>

                    <div className='w-180 shadow-lg flex flex-col bg-white justify-center items-center gap-1 text-sm border border-dashed border-opacity-50 border-allimmoDark rounded py-2 px-8  mt-5'>
                        <p className='text-lg'>Matches</p>
                        <div className='flex flex-row gap-3 text-sm italic'>
                            <p className='font-bold text-allimmoDark'>{userSummary.matches}</p>
                        </div>
                    </div>
                </div>
            </div>}

            {(userSummary && !isLoading && !userSummary.message) &&
            <div className='flex flex-col justify-center items-center mt-10'>
                <p className='text-center text-red-600 text-xl bg-red-100'>Kein User mit diesen Daten in der Datenbank gefunden</p>
                {inputData.email !== "" && <p className=''>Email: {inputData.email}</p>}
                {inputData.userID !== "" && <p className=''>User-ID: {inputData.userID}</p>}
            </div>}

            {isLoading && <CircularProgress className='mt-10' size={60} />}

            {!isLoading &&
            <div className='flex flex-row gap-3 mt-8'>
                {!userSummary && <button onClick={loadUserSummary} disabled={isButtonDisabled} className={`border border-allimmoDark px-3 py-1 rounded ${isButtonDisabled ? "opacity-50" : "hover:bg-blue-300 bg-blue-200 cursor-pointer"}`}>Abrufen</button>}
                {userSummary && <button onClick={() => setUserSummary()} className={`border border-allimmoDark px-3 py-1 rounded bg-blue-200 cursor-pointer`}>Neue Abfrage starten</button>}
            </div>}
        </div>
    )
}

export default SingleUserSummary
